/* MapBox Specific Styles */

.mapboxgl-map {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Vehicle marker styles */
.vehicle-marker {
  transition: all 0.3s ease;
  width: 30px;
  height: 30px;
  border: none;
  z-index: 1;
}

.vehicle-marker.followed {
  z-index: 2;
  transform-origin: center center;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0);
  }
}

/* Tooltip styles */
.mapbox-tooltip {
  transition: opacity 0.3s ease;
  z-index: 0;
  padding: 1px 3px !important;
}

/* Popup styles */
.mapboxgl-popup {
  max-width: 200px;
  z-index: 3;
}

.mapboxgl-popup-content {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Custom navigation controls */
.mapboxgl-ctrl-group {
  background-color: rgba(255, 255, 255, 0.9) !important;
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2) !important;
}

.mapboxgl-ctrl button {
  width: 32px !important;
  height: 32px !important;
}

/* Dark mode adjustments */
.dark-mode .mapboxgl-ctrl-group {
  background-color: rgba(35, 35, 35, 0.8) !important;
}

.dark-mode .mapboxgl-ctrl button {
  filter: invert(1);
}

/* Compass control */
.mapboxgl-ctrl-compass {
  transition: transform 0.3s ease;
}

/* Adjustments for 3D view */
.mapboxgl-marker {
  will-change: transform;
}

/* Custom zoom controls */
.zoom-button {
  background-color: rgba(25, 25, 25, 0.8) !important;
  color: white !important;
  font-size: 30px !important;
  width: 64px !important;
  height: 64px !important;
  border-radius: 50% !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .mapboxgl-ctrl-top-right {
    top: 70px !important; /* Move controls down to avoid header */
  }
  
  .mapboxgl-ctrl button {
    width: 36px !important;
    height: 36px !important;
  }
  
  .vehicle-marker {
    width: 24px;
    height: 24px;
    font-size: 14px !important;
    border: none;
  }
  
  .mapbox-tooltip {
    font-size: 10px !important;
    padding: 1px 2px !important;
  }
  
  /* Custom zoom controls for mobile */
  .zoom-button {
    width: 50px !important;
    height: 50px !important;
    font-size: 24px !important;
  }
}

/* Enhanced 3D building layer appearance - in JavaScript these would be passed as properties to the layer */
.building-3d-layer {
  /* These properties will be applied in the JS code, not in CSS */
  /* They're kept here for reference but should be used in MapBox layer definitions */
} 