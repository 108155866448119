.dark-theme-modal {
    z-index: 2000 !important;
    color: white;
}

.dark-theme-modal .modal-content {
    z-index: 2000 !important;
    max-width: 320px;
    background-color: rgba(28, 28, 30, 0.95);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
}

.modal-header {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h2 {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: white;
}

.close-button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    cursor: pointer;
    padding: 0;
    transition: color 0.2s;
}

.close-button:hover {
    color: white;
}

.modal-body {
    padding: 12px;
}

.control-section {
    margin-bottom: 16px;
}

.control-section h3 {
    font-size: 11px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
}

.control-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

/* Radio buttons for map type */
.map-type-options {
    display: flex;
    gap: 8px;
    background: rgba(255, 255, 255, 0.05);
    padding: 3px;
    border-radius: 8px;
}

.map-type-option {
    flex: 1;
    padding: 6px 8px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.8);
}

.map-type-option:hover {
    background: rgba(255, 255, 255, 0.1);
}

.map-type-option.active {
    background: rgba(255, 255, 255, 0.15);
    color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Toggle switches */
.switch-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

.switch-label {
    font-size: 12px;
    color: white;
}

.switch {
    position: relative;
    width: 36px;
    height: 20px;
    margin: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

input:checked + .slider {
    background-color: #34C759;
}

input:checked + .slider:before {
    transform: translateX(16px);
}

/* Font size selector */
.font-size-selector {
    display: flex;
    gap: 6px;
    background: rgba(255, 255, 255, 0.05);
    padding: 3px;
    border-radius: 8px;
    margin-top: 8px;
}

.font-size-option {
    flex: 1;
    padding: 6px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;
    color: rgba(255, 255, 255, 0.8);
}

.font-size-option:hover {
    background: rgba(255, 255, 255, 0.1);
}

.font-size-option.active {
    background: rgba(255, 255, 255, 0.15);
    color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Modal overlay */
.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

/* Responsive adjustments */
@media (max-width: 576px) {
    .dark-theme-modal .modal-content {
        margin: 8px;
        width: calc(100% - 16px);
        max-width: 280px;
    }

    .map-type-options,
    .font-size-selector {
        flex-direction: column;
        gap: 4px;
    }

    .map-type-option,
    .font-size-option {
        padding: 8px;
    }
}