.incident-form-modal {
    position: fixed;
    left: 25%;
    transform: none;
}

.incident-form-modal .modal-content {
    background-color: rgba(40, 44, 52, 0.95);
    border-radius: 12px;
    border: none;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
    max-width: 320px;
    margin: 0 auto;
}

.incident-form-modal .modal-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 13px 20px;
}

.incident-form-modal .modal-title {
    font-size: 20px;
    font-weight: 600;
    color: white;
    margin: 0;
}

.incident-form-modal .modal-body {
    padding: 13px 20px;
}

.incident-form-modal .form-label {
    font-size: 13px;
    color: white;
    font-weight: 500;
    margin-bottom: 6px;
}

.incident-form-modal .form-control {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    border-radius: 6px;
    padding: 7px 12px;
}

.incident-form-modal .form-control:focus {
    background-color: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
    color: white;
    box-shadow: none;
}

.incident-form-modal .form-control::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.incident-form-modal .form-control:disabled {
    background-color: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.3);
}

.incident-form-modal .form-select {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    border-radius: 6px;
    padding: 7px 12px;
}

.incident-form-modal .form-select:focus {
    background-color: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
    color: white;
    box-shadow: none;
}

.incident-form-modal .modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 13px 20px;
}

.incident-form-modal .btn-primary {
    background-color: #34C759;
    border: none;
    padding: 7px 14px;
    border-radius: 6px;
    font-weight: 500;
    transition: background-color 0.2s;
}

.incident-form-modal .btn-primary:hover {
    background-color: #2FB350;
}

.incident-form-modal .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
    opacity: 0.7;
    transition: opacity 0.2s;
}

.incident-form-modal .btn-close:hover {
    opacity: 1;
}

/* Position the modal
.incident-form-modal {
    position: fixed;
    left: 40%;
    transform: translateX(-50%);
}
    */

/* Style for select dropdowns */
.incident-form-modal select.form-control,
.incident-form-modal .form-select {
    background-color: rgba(40, 44, 52, 0.95);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-appearance: none;  /* Remove default arrow in WebKit browsers */
    -moz-appearance: none;     /* Remove default arrow in Firefox */
    appearance: none;          /* Remove default arrow */
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    padding-right: 2.5rem;
}

/* Remove default arrow in IE */
.incident-form-modal select.form-control::-ms-expand,
.incident-form-modal .form-select::-ms-expand {
    display: none;
}

/* Style for dropdown options */
.incident-form-modal select.form-control option,
.incident-form-modal .form-select option {
    background-color: rgba(40, 44, 52, 0.95);
    color: white;
}

/* Style for select when focused */
.incident-form-modal select.form-control:focus,
.incident-form-modal .form-select:focus {
    background-color: rgba(40, 44, 52, 0.95);
    color: white;
    border-color: rgba(255, 255, 255, 0.3);
    box-shadow: none;
}

/* Mobile responsive styles */
@media screen and (max-width: 768px) {
    .incident-form-modal {
        left: 50%; /* Center on mobile */
    }

    .incident-form-modal .modal-content {
        max-width: 192px; /* 60% of 320px */
        transform: scale(0.6);
        transform-origin: top center;
    }

    .incident-form-modal .modal-title {
        font-size: 13px; /* 60% of 22px */
    }

    .incident-form-modal .modal-header {
        padding: 9px 12px; /* 60% of 15px 20px */
    }

    .incident-form-modal .modal-body {
        padding: 9px 12px;
    }

    .incident-form-modal .form-label {
        font-size: 8px; /* 60% of 14px */
        margin-bottom: 5px;
    }

    .incident-form-modal .form-control {
        padding: 5px 7px; /* 60% of 8px 12px */
        font-size: 8px;
    }

    .incident-form-modal .modal-footer {
        padding: 9px 12px;
    }

    .incident-form-modal .btn {
        padding: 5px 10px; /* 60% of original padding */
        font-size: 8px;
    }

    .incident-form-modal select.form-control,
    .incident-form-modal .form-select {
        font-size: 8px;
        padding: 5px 7px;
    }
}

/* Reduce spacing between form groups */
.incident-form-modal .form-group {
    margin-bottom: 9px;
}