.cad-marker {
    width: 32px;
    height: 32px;
  }
  
  .cad-toast {
    min-width: 300px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .cad-toast h4 {
    color: #d32f2f;
    
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .cad-toast p {
    margin: 4px 0;
    color: #333;
  }
  
  .cad-popup {
    padding: 10px;
  }
  
  .cad-popup h3 {
    color: #000000;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .cad-popup p {
    margin: 4px 0;
    color: #000000;
  }
  
  .cad-popup strong {
    color: #333;
  }
  
  
  .custom-popup .leaflet-popup-content {
    margin: 8px 12px;
    line-height: 1.4;
  }
  
  .custom-popup .leaflet-popup-content-wrapper {
    padding: 2px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.95);
    color: #000000;
  }
  
  .custom-popup .leaflet-popup-tip {
    background: rgba(255, 255, 255, 0.95);
  }
  
  .cad-popup h3 {
    white-space: normal;
    word-wrap: break-word;
  }
  
  .cad-popup p {
    white-space: normal;
    word-wrap: break-word;
  }
  
  /* Toast Notifications */
  .cad-toast-content {
    min-width: 350px;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.98);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 12px;
  }
  
  /* Fire Call Styling */
  .cad-toast-fire {
    border-left: 4px solid #ff4444;
  }
  
  /* EMS Call Styling */
  .cad-toast-ems {
    border-left: 4px solid #2d8659;
  }
  
  .cad-toast-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .cad-toast-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    flex-shrink: 0;
  }
  
  .cad-toast-title {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0;
  }
  
  .cad-toast-details {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .cad-toast-label {
    font-weight: 500;
    color: #666;
    padding-right: 8px;
  }
  
  .cad-toast-value {
    color: #1a1a1a;
  }
  
  /* Popup Styling */
  .custom-popup .leaflet-popup-content-wrapper {
    padding: 0;
    border-radius: 8px;
    background: white;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    border: 2px solid;
    border-color: #ff4444; /* Fire color - will be dynamically set in JS */
  }
  
  .custom-popup .leaflet-popup-content {
    margin: 0;
    width: 100% !important;
  }
  
  .cad-popup {
    padding: 16px;
  }
  
  .cad-popup-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    background: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
  }
  
  .cad-popup-header h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #1a1a1a;
  }
  
  .cad-popup-body {
    padding: 16px;
  }
  
  .cad-popup-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 12px;
    font-size: 15px;
  }
  
  .cad-popup-label {
    font-weight: 500;
    color: #666;
    min-width: 100px;
  }
  
  .cad-popup-value {
    color: #1a1a1a;
    font-weight: 500;
  }
  
  /* Modern Apple-style Popup */
  .custom-popup .leaflet-popup-content-wrapper {
    padding: 0;
    border-radius: 12px;
    background: rgba(250, 250, 250, 0.98);
    box-shadow: 0 8px 30px rgba(0,0,0,0.12), 0 2px 8px rgba(0,0,0,0.08);
    max-width: 320px;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  
  .custom-popup .leaflet-popup-content {
    margin: 0;
    width: 100% !important;
  }
  
  .custom-popup .leaflet-popup-tip {
    background: rgba(250, 250, 250, 0.98);
    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  }
  
  .cad-popup {
    padding: 0;
  }
  
  .cad-popup-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 12px 12px 0 0;
  }
  
  .cad-popup-header img {
    width: 28px;
    height: 28px;
    object-fit: contain;
  }
  
  .cad-popup-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1f;
    line-height: 1.3;
  }
  
  .cad-popup-body {
    padding: 16px 20px;
  }
  
  .cad-popup-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px 12px;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .cad-popup-label {
    font-weight: 500;
    color: #86868b;
    min-width: 90px;
  }
  
  .cad-popup-value {
    color: #1d1d1f;
    font-weight: 400;
  }
  
  /* Toast Notifications */
  .cad-toast-content {
    min-width: 320px;
    max-width: 400px;
    background: rgba(250, 250, 250, 0.98);
    border-radius: 12px;
    box-shadow: 0 8px 30px rgba(0,0,0,0.12), 0 2px 8px rgba(0,0,0,0.08);
    padding: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  
  .cad-toast-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  
  .cad-toast-icon {
    width: 28px;
    height: 28px;
    object-fit: contain;
  }
  
  .cad-toast-title {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1f;
    margin: 0;
    line-height: 1.3;
  }
  
  .cad-toast-details {
    padding: 12px 20px;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px 12px;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .cad-toast-label {
    font-weight: 500;
    color: #86868b;
    min-width: 90px;
  }
  
  .cad-toast-value {
    color: #1d1d1f;
    font-weight: 400;
  }
  
  /* Type-specific styling */
  .cad-toast-fire {
    border-left: none;
    background: linear-gradient(to right, #ff4444 4px, transparent 4px);
  }
  
  .cad-toast-ems {
    border-left: none;
    background: linear-gradient(to right, #2d8659 4px, transparent 4px);
  }
  
  .cad-toast-mva {
    border-left: none;
    background: linear-gradient(to right, #ffa500 4px, transparent 4px);
  }
  
  /* Dark Theme Popup Styling */
  .custom-popup .leaflet-popup-content-wrapper {
    background: #1a1a1a;
    color: #fff;
    border-radius: 8px;
    padding: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    max-width: 320px;
    border: none;
  }
  
  .custom-popup .leaflet-popup-tip {
    background: #1a1a1a;
  }
  
  .custom-popup .leaflet-popup-content {
    margin: 0;
    width: 100% !important;
  }
  
  .cad-popup {
    padding: 0;
  }
  
  .cad-popup-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(40, 40, 40, 0.95);
    border-radius: 8px 8px 0 0;
  }
  
  .cad-popup-header img {
    width: 24px;
    height: 24px;
  }
  
  .cad-popup-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
  
  .cad-popup-body {
    padding: 16px;
    background: rgba(26, 26, 26, 0.95);
    border-radius: 0 0 8px 8px;
  }
  
  .cad-popup-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px 12px;
    font-size: 14px;
  }
  
  .cad-popup-label {
    color: rgba(255, 255, 255, 0.7);
    font-weight: normal;
  }
  
  .cad-popup-value {
    color: #fff;
    font-weight: normal;
  }
  
  /* Toast Notifications */
  .cad-toast-content {
    background: #1a1a1a;
    color: #fff;
    border-radius: 8px;
    padding: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    min-width: 320px;
    max-width: 400px;
  }
  
  .cad-toast-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(40, 40, 40, 0.95);
    border-radius: 8px 8px 0 0;
  }
  
  .cad-toast-icon {
    width: 24px;
    height: 24px;
  }
  
  .cad-toast-title {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
  
  .cad-toast-details {
    padding: 16px;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px 12px;
    font-size: 14px;
    background: rgba(26, 26, 26, 0.95);
    border-radius: 0 0 8px 8px;
  }
  
  .cad-toast-label {
    color: rgba(255, 255, 255, 0.7);
    font-weight: normal;
  }
  
  .cad-toast-value {
    color: #fff;
    font-weight: normal;
  }
  
  /* Type indicators */
  .cad-toast-fire, .cad-toast-ems, .cad-toast-mva {
    border: none;
  }
  
  .Toastify__toast {
    background: transparent !important;
    box-shadow: none !important;
  }
  
  .Toastify__close-button {
    color: rgba(255, 255, 255, 0.7) !important;
    opacity: 1 !important;
  }
  
  .Toastify__close-button:hover {
    color: #fff !important;
  }